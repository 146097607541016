<template>
  <Breadcrumbs title="News & Media" />
  <!-- News start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="bee-news">
        <div class="news-image">
          <div class="news-date">27 Dec<span>2017</span></div>
          <img src="../assets/images/latest-post1.jpg" alt="News 1" />
        </div>
        <div class="news-details">
          <h4><a href="" title="">Industry's standard dummy text ever since</a></h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer.
          </p>
          <div class="blog-post-by">
            <ul>
              <li><i class="fa fa-user"></i>By Admin</li>
              <li><i class="fa fa-comment"></i>12 comments</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bee-news">
        <div class="news-image">
          <div class="news-date">16 Dec<span>2017</span></div>
          <img src="../assets/images/latest-post2.jpg" alt="News 2" />
        </div>
        <div class="news-details">
          <h4><a href="" title="">Industry's standard dummy text ever since</a></h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer.
          </p>
          <div class="blog-post-by">
            <ul>
              <li><i class="fa fa-user"></i>By Admin</li>
              <li><i class="fa fa-comment"></i>12 comments</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bee-news">
        <div class="news-image">
          <div class="news-date">09 Dec<span>2017</span></div>
          <img src="../assets/images/latest-post3.jpg" alt="News 3" />
        </div>
        <div class="news-details">
          <h4><a href="" title="">Industry's standard dummy text ever since</a></h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer.
          </p>
          <div class="blog-post-by">
            <ul>
              <li><i class="fa fa-user"></i>By Admin</li>
              <li><i class="fa fa-comment"></i>12 comments</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bee-news">
        <div class="news-image">
          <div class="news-date">15 Dec <span>2017</span></div>
          <img src="../assets/images/latest-post4.jpg" alt="News 4" />
        </div>
        <div class="news-details">
          <h4><a href="" title="">Industry's standard dummy text ever since</a></h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer.
          </p>
          <div class="blog-post-by">
            <ul>
              <li><i class="fa fa-user"></i>By Admin</li>
              <li><i class="fa fa-comment"></i>12 comments</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bee-news">
        <div class="news-image">
          <div class="news-date">15 Dec <span>2017</span></div>
          <img src="../assets/images/latest-post2.jpg" alt="News 5" />
        </div>
        <div class="news-details">
          <h4><a href="" title="">Industry's standard dummy text ever since</a></h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer.
          </p>
          <div class="blog-post-by">
            <ul>
              <li><i class="fa fa-user"></i>By Admin</li>
              <li><i class="fa fa-comment"></i>12 comments</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bee-news">
        <div class="news-image">
          <div class="news-date">15 Dec <span>2017</span></div>
          <img src="../assets/images/latest-post1.jpg" alt="News 6" />
        </div>
        <div class="news-details">
          <h4><a href="" title="">Industry's standard dummy text ever since</a></h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer.
          </p>
          <div class="blog-post-by">
            <ul>
              <li><i class="fa fa-user"></i>By Admin</li>
              <li><i class="fa fa-comment"></i>12 comments</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="page-navigation">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item disabled">
              <a class="page-link" href="#" tabindex="-1"
                ><i class="fa fa-angle-left" aria-hidden="true"></i
              ></a>
            </li>
            <li class="page-item pagin-active"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item">
              <a class="page-link" href="#"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <!-- News End -->
  <Newsletter />
</template>

<script>
// @ is an alias to /src
// import BeeSlider from '@/components/Slider.vue';

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Newsletter from "@/components/Newsletter.vue";

export default {
  name: "News",
  components: {
    Breadcrumbs,
    Newsletter,
  },
};
</script>
